export default {
  name: "PositionKasTeller",
  data() {
    return {
      dataForm: {
        tellerId: "",
        userName: "",
        userCode: "",
        date: "",
        exportContent: "",
        exportToExcel: {
          data: [],
          header: [],
        },
        total: {
          totalSaldoAwal: 0,
          totalSetoran: 0,
          totalPenarikan: 0,
          totalSaldoAkhir: 0,
        },
      },
      property: {
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
        listElement: {
          posisiKasTeller: {
            rows: 0,
            perPage: 10,
            currentPage: 0,
            downloading: false,
            message: "",
          },
        },
        animation: {
          isDownloadingFile: false,
        },
      },
      table: {
        data: {
          posisiKasTeller: [],
        },
      },
      options: {
        user: [],
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        tellerId: this.dataForm.tellerId,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/position-kas-teller/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Posisi_Kas_Teller.${fileType === "xls" ? "xlsx" : "pdf"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    getCurrentUserFromSession() {
      const getDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      console.log(getDataFromSession);
      this.dataForm.userCode = getDataFromSession.userCode;
    },
    async getReferenceUser() {
      this.options.user = [{ text: "-- Pilih --", value: "" }];
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "user/tellers",
          endPoint: "parameter",
          payload: {
            isIncluded: false,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.userCode} - ${index.userName}`;
            const value = index.userId;
            this.options.user.push({ text, value });
          });
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    handleCatchErrorPositionKasTeller(error) {
      console.log(error.response);
      this.table.data.posisiKasTeller = [];
      this.property.listElement.posisiKasTeller.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getPositionCashTeller() {
      this.table.data.posisiKasTeller = [];
      this.property.listElement.posisiKasTeller.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/position-kas-teller",
          payload: {
            tellerId: this.dataForm.tellerId,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.posisiKasTeller = resp.data.data;
          } else {
            this.property.listElement.posisiKasTeller.message =
              resp.data.message;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorPositionKasTeller(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.posisiKasTeller.downloading = false;
        }, timeout);
      }
    },
    handleSystemDate() {
      this.dataForm.date = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  computed: {
    currentUserRole() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).roleId;
      return getSession;
    },
    currentUserLogged() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).userName;
      return getSession;
    },
    totalSaldoAwal() {
      return this.table.data.posisiKasTeller.reduce(
        (acc, item) => acc + item.saldoAwal,
        0
      );
    },
    totalSetoran() {
      return this.table.data.posisiKasTeller.reduce(
        (acc, item) => acc + item.setoran,
        0
      );
    },
    totalPenarikan() {
      return this.table.data.posisiKasTeller.reduce(
        (acc, item) => acc + item.penarikan,
        0
      );
    },
    totalSaldoAkhir() {
      return this.table.data.posisiKasTeller.reduce(
        (acc, item) => acc + item.saldoAkhir,
        0
      );
    },
  },
  mounted() {
    this.getCurrentUserFromSession();
    this.getReferenceUser();
    this.handleSystemDate();
  },
};
